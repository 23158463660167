﻿import $ from 'jquery';
import Atom14 from './atom14.js';

$(function () {
  console.log('index.js');
  showRecentResults(10);

  init();
});

// this is called once Google JS client has loaded
function init () {
  console.log('init');
  // NOTE: the key is limited to our domains so it can be exposed here
  const atom14 = new Atom14('AIzaSyDZXXhiADdhl_5O7bJvC1w6Mrg1mEyf3To'); //  old key: AIzaSyDWkO4ltYIla5kaqBkLafoBCExgCkUCKXE

  atom14.blogId = '232976335171599818';
  atom14.labels = 'News';
  atom14.blogger('#newsFeed', 'News');

  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 50) {
      $('#back-to-top').fadeIn();
    } else {
      $('#back-to-top').fadeOut();
    }
  });

  // scroll body to 0px on click
  $('#back-to-top').on('click', function () {
    $('#back-to-top').tooltip('hide');
    $('body,html').animate({
      'scrollTop': 0
    }, 800);
    return false;
  });
}

/**
 * show recent match results
 * @param {number} n - number of results to show
 */
function showRecentResults (n) {
  console.log('showRecentResults()');

  const $target = $('#recentResults');
  if ($target.length !== 1) {
    return false;
  }

  const params = { 'seasonId': null, 'n': n };
  $.get('../database/getResults.php', params, 'json')
    .done(function (response) {
      let a = 'alert-info';
      if (response.count > 0) {
        $.each(response.results, function (k, result) {
          switch (result.event) {
            case 'Ladies':
              a = 'alert-warning';
              break;
            case 'Men':
              a = 'alert-success';
              break;
            case 'Mixed':
              a = 'alert-info';
              break;
            default:
              a = 'alert-danger';
          }

          const h = `<div class="alert ${a}" role="alert">
            ${result.date}: ${result.homeTeam} ${result.result} ${result.awayTeam}&nbsp;<span class="badge">${result.event} ${result.division}</span>
          </div>`;
          $target.append(h);
        });
      } else {
        $target.append(`<div class="alert alert-warning" role="alert">No results for current season</div>`);
      }
    })
    .fail((error) => console.log(error));
}

export default init;
