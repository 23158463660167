/*
    Author:     Simon Gilhooly
    Version:    2.0
    Date:       2013-01-17

    Atom14 Google Library -

    Google Javascript client library - https://developers.google.com/api-client-library/javascript/

    googleCal - this method retrieves forthcoming calendar events from a Google calendar
    https://developers.google.com/apis-explorer/#p/calendar/v3/
    https://developers.google.com/google-apps/calendar/

    blogger - this method retrieves blog entries with the specified label and inserts them into the specified element
    https://developers.google.com/blogger/docs/3.0/getting_started

    Requires JQuery
*/

import $ from 'jquery';
// @ts-ignore
import gapi from 'gapi';

class Atom14 {
  #apiKey = null;

  blogId = null;

  calId = null;

  labels = null;
  // apiKey = 'AIzaSyDWkO4ltYIla5kaqBkLafoBCExgCkUCKXE';

  /**
   *
   * @param {string} apiKey
   */
  constructor (apiKey) {
    this.#apiKey = apiKey;
  }

  /**
   *
   * @param {HTMLElement} target
   */
  googleCal (target) {
    // must set the id of the calendar and specify a target element before calling this function ....
    const self = this;
    const period = 15; // number of days ahead to retrieve events

    if ((this.calId !== null) && (target !== null)) {
      const today = new Date();
      const endDate = new Date();
      endDate.setMonth(endDate.getMonth(), endDate.getDate() + period); // set target period to X days ahead

      gapi.client.load('calendar', 'v3', function () {
        const request = gapi.client.calendar.events.list({
          'calendarId': this.calId,
          'fields': 'items(start/dateTime, summary, location, htmlLink)',
          'orderBy': 'startTime',
          'singleEvents': 'true',
          'timeMax': endDate,
          'timeMin': today
        });

        request.execute(function (/** @type {Object} */ calResponse) {
          for (let i = 0; i < calResponse.items.length; i++) {
            const calItem = calResponse.items[i];

            if ('start' in calItem) {
              const eventDate = self.formatGTime(calItem.start.dateTime);

              if (eventDate.valueOf() >= today.valueOf()) {
                if ('location' in calItem) {
                  $(target).append('<li>' + eventDate.toLocaleDateString() + ': <a href="' + calItem.htmlLink + '">' + calItem.summary + '</a>, ' + eventDate.toLocaleTimeString().slice(0, eventDate.toLocaleTimeString().lastIndexOf(':')) + ' @ ' + calItem.location + '</li>');
                } else {
                  $(target).append('<li>' + eventDate.toLocaleDateString() + ': <a href="' + calItem.htmlLink + '">' + calItem.summary + '</a></li>');
                }
              }
            }
          }
        });
      });
    }
  }

  /**
   *
   * @param {string} target -- anything that can be passed to jQuery
   * @param {string} [filter=null]
   */
  blogger (target, filter = null) {
    if (!this.blogId) {
      return;
    }

    const $target = $(target);
    if ($target.length !== 1) {
      return;
    }

    const url = `https://www.googleapis.com/blogger/v3/blogs/${this.blogId}/posts`;
    const params = `labels=${this.labels}&key=${this.#apiKey}`;

    const self = this;

    $.getJSON(url, params, function (response) {
      $target.html('');

      // loop through all the blog posts creating a div for each
      for (let i = 0; i < response.items.length; i++) {
        const item = response.items[i];

        // check whether item.labels array contains the filter string
        /** @type {Array} labels */
        const labels = item.labels;
        console.log(labels);

        if (filter === null || labels.includes(filter)) {
          let truncatedContent = item.content;
          if (item.content.indexOf("<a name='more'>") > 0) {
            truncatedContent = item.content.slice(0, item.content.indexOf("<a name='more'>")) + "<br/><a href='" + item.url + "'>Read More</a>";
          }

          const publishDate = self.formatGTime(item.published);
          const post = `<div class="box">
            <div class="post-header">
              <a href="${item.url}">
                <h2>${item.title}</h2>
              </a>
            </div>
            <div class="post-body">
              <p>${truncatedContent}</p>
            </div>
            <p class="post-footer">Posted on <span class="date">${publishDate.toLocaleDateString()}</span> by ${item.author.displayName}</p>
          </div>`;
          $target.append(post);
        }
      }
    });
  }

  /**
   *
   * @param {string} gCalTime
   * @returns string
   */
  formatGTime (gCalTime) {
    // this function converts the string representation of a date used by Google into a Javascript date object
    let remtxt = gCalTime;

    const consume = (/** @type {string} */ retxt) => {
      const match = remtxt.match(new RegExp('^' + retxt));
      if (match) {
        remtxt = remtxt.substring(match[0].length);
        return match[0];
      }
      return '';
    };

    // minutes of correction between gCalTime (2009-09-21T21:00:00.000Z) and GMT
    let totalCorrMins = 0;

    // match first four numeric characters
    const year = parseInt(consume('\\d{4}'));
    consume('-?');
    const month = parseInt(consume('\\d{2}'));
    consume('-?');
    const dateMonth = parseInt(consume('\\d{2}'));
    const timeOrNot = consume('T');

    // if time from server is not already in GMT, calculate offset
    let hours = 0;
    let mins = 0;

    // if a DATE-TIME was matched in the regex
    if (timeOrNot === 'T') {
      hours = parseInt(consume('\\d{2}'));
      consume(':?');
      mins = parseInt(consume('\\d{2}'));
      consume('(:\\d{2})?(\\.\\d{3})?');
      const zuluOrNot = consume('Z');

      if (zuluOrNot !== 'Z') {
        const corrPlusMinus = consume('[\\+\\-]');
        if (corrPlusMinus !== '') {
          const corrHours = consume('\\d{2}');
          consume(':?');
          const corrMins = consume('\\d{2}');
          totalCorrMins = (corrPlusMinus === '-' ? 1 : -1) *
          (Number(corrHours) * 60 +
          (corrMins === '' ? 0 : Number(corrMins)));
        }
      }
    }

    // get time since epoch and apply correction, if necessary
    // relies upon Date object to convert the GMT time to the local
    // timezone
    const originalDateEpoch = Date.UTC(year, month - 1, dateMonth, hours, mins);
    const gmtDateEpoch = originalDateEpoch + totalCorrMins * 1000 * 60;
    const ld = new Date(gmtDateEpoch);

    return ld;
  }
};

export default Atom14;
